import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import VodList from "../components/vodlist";


const VODListingPage = ({ data }) => {
  return (
    <Layout pageTitle="KSEA NBM VODs">
      <h1 className="text-2xl font-bold pb-6">All On-Demand Videos</h1>

      <VodList data={data} tabIndex="6" />

      <div className="my-10">
        &nbsp;
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    teamOne: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "Auburn" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, height: 180)
            }
          }
          date
        }
      }
    }

    teamTwo: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "El Segundo" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          date
        }
      }
    }

    teamThree: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "Stafford" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          date
        }
      }
    }

    teams: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "Teams" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          date
        }
      }
    }

    awards: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "Awards" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          date
        }
      }
    }

    dayOne: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "Day One" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          date
        }
      }
    }

    dayTwo: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "Day Two" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          date
        }
      }
    }

    dayThree: allMdx(
      sort: { fields: frontmatter___displayorder, order: ASC }
      filter: {
        frontmatter: { published: { eq: true }, team: { in: "Day Three" } }
      }
    ) {
      nodes {
        slug
        frontmatter {
          title
          authors
          team
          department
          short_description
          length
          m3u8_url
          thumbnail {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, width: 400)
            }
          }
          date
        }
      }
    }


  }
`;

export default VODListingPage;
